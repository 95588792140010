<template>
  <div>
    <ListPricesSelfPay></ListPricesSelfPay>
  </div>
</template>

<script> 

  
import ListPricesSelfPay from '@core/components/priceselfpay/ListPricesSelfPay.vue'

export default {
  components: {
    ListPricesSelfPay,
    // AddGoogleMap,
  },
  data() {
    return {}
  },
}
</script>

<style>

</style>
